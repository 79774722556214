@charset "UTF-8";
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  width: 56px;
  height: 56px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background-color: rgba(120, 119, 123, 0.2);
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  border-radius: 50%;
}
.slick-prev:hover,
.slick-next:hover {
  background: #fff;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "Arial", sans-serif;
  font-size: 45px;
  line-height: 1;
  color: #78777B;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev:before:hover,
.slick-next:before:hover {
  color: #fff;
}

.slick-prev {
  left: 0;
  z-index: 1;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "â®";
}
[dir=rtl] .slick-prev:before {
  content: "â¯";
}

.slick-next {
  right: 0;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "â¯";
}
[dir=rtl] .slick-next:before {
  content: "â®";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0;
  font-size: 0;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "â€¢";
  width: 20px;
  height: 20px;
  font-family: Arial, sans-serif;
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 1;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -ms-touch-action: pan-y;
      touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list.dragging {
  cursor: pointer;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: block;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

#heading_slider {
  margin-top: 0;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 86%, 0% 100%);
          clip-path: polygon(0 0, 100% 0, 100% 86%, 0% 100%);
}
#heading_slider .heading-img {
  background-size: cover;
  background-position: center;
  display: none;
  height: 600px;
  overflow: hidden;
  position: relative;
}
#heading_slider .heading-title {
  width: 80%;
  margin: 50px auto;
  line-height: 1.4;
  padding: 10px;
  max-width: 1250px;
  z-index: 1;
}
@media (min-width: 576px) {
  #heading_slider .heading-title {
    margin: 80px auto;
    padding: 20px;
    line-height: 60px;
  }
}
@media (min-width: 992px) {
  #heading_slider .heading-title {
    margin: 200px auto;
    padding: 40px;
  }
}
#heading_slider .heading-title h3, #heading_slider .heading-title h4 {
  margin: 0;
}
#heading_slider .heading-title h3 {
  display: block;
  padding: 15px;
  vertical-align: middle;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  font-size: 2.75rem;
  font-weight: 600;
}
@media (min-width: 992px) {
  #heading_slider .heading-title h3 {
    font-size: 3.5rem;
  }
}
@media (min-width: 1200px) {
  #heading_slider .heading-title h3 {
    font-size: 3.75rem;
  }
}
#heading_slider .heading-title h4 {
  display: block;
  vertical-align: middle;
  padding: 0 20px;
  font-weight: 200;
  color: #E4E6EB;
  background-color: rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
}
#heading_slider .heading-title .button {
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  margin: 10px 0;
}
#heading_slider .heading-title .button:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  -webkit-box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.2);
          box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.2);
}
#heading_slider .heading-title .button:active, #heading_slider .heading-title .button:focus {
  outline: none;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.welcome-con {
  background-color: #E31937;
  padding-top: 100px;
  margin-top: -100px;
}
.welcome-con .welcome {
  padding: 40px;
  margin: 0 auto;
  max-width: 1280px;
}
.welcome-con .welcome h1,
.welcome-con .welcome p {
  color: #fff;
}
.welcome-con .welcome p {
  line-height: 1.7;
}
.welcome-con .welcome img {
  margin: 10px 0;
  -webkit-box-shadow: 0 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.2), 0 0.6rem 2rem 0 rgba(0, 0, 0, 0.19);
          box-shadow: 0 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.2), 0 0.6rem 2rem 0 rgba(0, 0, 0, 0.19);
}
.welcome-con .welcome .welcome-btn {
  border: 2px solid #fff;
  margin: 10px 0;
}
.welcome-con .welcome .welcome-btn:hover {
  background-color: #fff;
  color: #E31937;
}
.welcome-con .welcome .company-logos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.welcome-con .welcome .company-logos .logo {
  max-width: 100%;
  margin: 10px 10px 10px 0;
  height: 75px;
}
.welcome-con .welcome .company-logos .logo img {
  background: #fff;
  padding: 10px;
}

.corporate-message {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .corporate-message {
    margin-bottom: 0;
  }
}
.corporate-message .corporate-message-img {
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.corporate-message .image-overlay:before {
  content: "";
  background: rgba(0, 0, 0, 0.4);
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.corporate-message .message {
  padding: 40px;
  margin: auto;
  z-index: 1;
}
.corporate-message .message h3 {
  vertical-align: middle;
  display: block;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}
.corporate-message .message .message-content {
  color: #fff;
}
.corporate-message .message .message-btn {
  border: 2px solid #fff;
  background: none;
  margin: 10px 0;
}
.corporate-message .message .message-btn:hover {
  background-color: #fff;
  color: #78777B;
}

.blog h3 {
  display: block;
  font-weight: 600;
  text-transform: uppercase;
}
.blog #blog h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
  margin-bottom: 0;
}
.blog #blog h4 {
  font-size: 0.8rem;
  font-style: italic;
  font-weight: 600;
  color: #78777B;
  margin-top: 0;
}
.blog #blog .content {
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  line-height: 1.4;
}
.blog #blog .content a {
  color: #fff;
  display: inline-block;
  text-decoration: none;
  margin: 0.5rem 0;
  text-transform: uppercase;
  text-align: center;
  background-color: #E31937;
  border: 1px solid #E31937;
  padding: 0.5rem 1rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 1px;
}

@media (min-width: 992px) {
  #social-feeds {
    -webkit-clip-path: polygon(0 0, 100% 8%, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 8%, 100% 100%, 0% 100%);
  }
}
@media (min-width: 1400px) {
  #social-feeds {
    -webkit-clip-path: polygon(0 0, 100% 13%, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 13%, 100% 100%, 0% 100%);
  }
}
#social-feeds iframe {
  max-height: 400px;
}
#social-feeds .social-img {
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;
  z-index: 0;
}
#social-feeds .image-overlay:before {
  content: "";
  background: rgba(230, 224, 220, 0.9);
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
#social-feeds .feeds {
  padding: 40px;
  margin: 0 auto;
  max-width: 1360px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (min-width: 992px) {
  #social-feeds .feeds {
    padding: 100px 40px 40px 40px;
  }
}
#social-feeds .feed-col {
  margin: 0 10px;
  min-width: 300px;
}
#social-feeds .social-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#social-feeds .social-header span {
  font-size: 1.75rem;
  margin-right: 8px;
  color: #000;
}
#social-feeds h4 {
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}
#social-feeds .feed {
  background: #fff;
  width: 300px;
  height: 400px;
  margin: 10px auto;
}
#social-feeds .social-link {
  text-align: center;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-header {
  padding: 0 1rem;
  background-color: #fff;
  color: #E31937;
}
.modal-header .modal-title {
  color: #E31937;
  padding: 1rem 0 0;
  font-size: 2.2rem;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #fff;
  color: #E31937;
}

.modal-content {
  position: relative;
  background-color: #fff;
  margin: 10rem auto 0;
  padding: 1rem;
  border: 1px solid #fff;
  width: 70%;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
          animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s;
}

/* The Close Button */
.close {
  color: #78777B;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

@-webkit-keyframes animatetop {
  from {
    top: -30rem;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -30rem;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
.corporate-message-blog-con {
  margin: 0 auto;
  max-width: 1280px;
  padding: 40px;
}